// extracted by mini-css-extract-plugin
export var absoluteSubmit = "question-module--absoluteSubmit--EJptB";
export var actions = "question-module--actions--g4kwd";
export var chevron = "question-module--chevron--wXayN";
export var focusVisible = "question-module--focus-visible--mtmkn";
export var hsErrorMsg = "question-module--hs-error-msg--aHNLr";
export var hs_submit = "question-module--hs_submit--WLjYD";
export var icon = "question-module--icon--ddkz1";
export var input = "question-module--input--kmfmo";
export var question = "question-module--question--IlkrU";
export var question__error = "question-module--question__error--Ys0sW";
export var question__help = "question-module--question__help--NXXrz";