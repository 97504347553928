import React, {useEffect, useState} from "react";
import { graphql, Script } from "gatsby";
import PropTypes from 'prop-types';

 /**
 * Renders Hubspot form
 * 
 * CMS : DatoCmsComponentHubspotForm
 */
const HubSpot = ({data, uniqueid, prefillResults, loadonscroll, ...other}) => {
  const {formId, portalId, region} = data || {};
  const [hubSpotLoaded, setHubSpotLoaded ] = useState(false);
  let componentId = `hubspotForm-${formId}${uniqueid ? '--'+uniqueid : ''}`;
  let hubspotScript = 'https://js.hsforms.net/forms/v2.js';

  useEffect(() => {
    /**
     * Hubspot Render
     */
    const  hubspotFormLoader = () => {
      if(window.hbspt) {
          window.hbspt.forms.create({
          "portalId": portalId,
          "formId": formId,
          "region": region,
          "target": '#'+componentId
        });
      }
    }
    if(hubSpotLoaded === 'ready'){
      hubspotFormLoader();
    }
  }, [hubSpotLoaded, portalId, formId, region, componentId, prefillResults]);

  useEffect(() => {
    function handler(event) {
      if(prefillResults){
        if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
          if (event.data.id === formId){
            for (const key in prefillResults) {
              let hubspotFieldGroup = document.querySelector(`label[placeholder*="${key}"]`).parentElement;
              hubspotFieldGroup.querySelector('input').setAttribute("value", prefillResults[key]);
            }
          }
        }
      }
    }
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    }
}, [formId, prefillResults])

 

  return (
    <>
      {
        <Script src={hubspotScript} onLoad={()=>setHubSpotLoaded('ready')} strategy="idle" />
      }
      {
        data && 
        <div className="hubSpot" id={componentId} {...other} />
      }
    </>
  )
}

HubSpot.propTypes = {
  /**
   * CMS Data
   */
  data : PropTypes.shape({
  formId: PropTypes.string,
  portalId: PropTypes.string,
  region: PropTypes.string,
  }),
  loadonscroll : PropTypes.bool
};

export default HubSpot;

export const HubspotFragment = graphql`
  fragment HubspotFragment on DatoCmsComponentHubspotForm {
    formId
    portalId
    region
  }`;