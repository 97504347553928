import React, {useState, useEffect} from 'react';
import { textInput } from './textInput.module.scss';
import PropTypes from 'prop-types';

const TextInput = ({data, currentValue, passValueFunc}) => {
  const { keyId, prompt} = data || {};
  const [inputVal, setInputVal ] = useState('');
  
  useEffect(() => {   
    setInputVal(currentValue);
  }, [currentValue, data])

  const textHandler = (e) => {
    let value = e.target.value;
    setInputVal(value)
    passValueFunc(value); 
  }

  return (
    <>
      <label className={`prompt`} htmlFor={`question-${keyId}`}>{prompt}</label>
      <input className={textInput} onChange={(e)=>textHandler(e)} value={inputVal} id={`question-${keyId}`} type="text" />
    </>
  )
}

TextInput.propTypes = {
  data : PropTypes.shape({
    /**
     * uniqueId for fieldset
     */
    keyId: PropTypes.string,
    prompt: PropTypes.string,
  }),
  /**
   * Current state to prefill
   */
  currentValue: PropTypes.string,
   /**
   * function to lift state
   */
  passValueFunc: PropTypes.func
}

export default TextInput;
