// extracted by mini-css-extract-plugin
export var absoluteSubmit = "results-module--absoluteSubmit--YtmtG";
export var actions = "results-module--actions--5umYc";
export var chevron = "results-module--chevron--bNXcF";
export var focusVisible = "results-module--focus-visible--hi6s5";
export var hsErrorMsg = "results-module--hs-error-msg--19y+U";
export var hsFormField = "results-module--hs-form-field--bLUEL";
export var hs_submit = "results-module--hs_submit--KN26v";
export var icon = "results-module--icon--nmQ-Y";
export var input = "results-module--input--gXmJf";
export var result = "results-module--result--t4-2X";
export var result__answers = "results-module--result__answers--Oikc6";
export var result__none = "results-module--result__none--2yaWD";
export var result__results = "results-module--result__results--LT42W";
export var result__results__label = "results-module--result__results__label--tcdkW";
export var result__results__percent = "results-module--result__results__percent--pGHSB";