import React, {useState, useContext} from "react"
import { graphql } from "gatsby";
import RenderModules from "../../lib/renders/renderModules";
import Papa from "papaparse";
import Hero from '../../modules/hero/hero';
import Image from "../../components/image/image";
import Question from './question/question';
import Loading from "../../components/loading/loading";
import Results from "./result/result";
import { LangContext } from '../../context/LocaleContext';

import {calculator, calculator__nav, calculator__loading, calculator__loading__loader, calculator__imageTop, calculator__imageBottom} from './calculator.module.scss';

const CalculatorPage = ({data, location}) => {
  const { getTranslation } = useContext(LangContext);

  const {backgroundImage, calculatorQuestions, dataSheet, pageBuilder, ...resultsData} = data?.datoCmsPageCalculator || {};
  const totalQuestions = calculatorQuestions.length;

  const [currentStep, setCurrentStep] = useState(0);
  const [valid, setValid] = useState(true);

  const [formAnswers, setFormAnswers] = useState(
    calculatorQuestions.map((question)=>question.prompt).reduce((accumulator, value)=>{
      return {...accumulator, [value]: ''};
    }, {})
  );

  const [screen, setScreen] = useState('calculator');
  const [finalAnswer, setFinalAnswer] = useState(null);
  const [image, setImage] = useState(false);
  let formArray = Object.values(formAnswers);

  //Function to pull the correct ROI Row (via the key)
  const getResults = (url, key, keyVal, rowKeyWords) => {

    Papa?.parse(url, {
      download: true,
      stream: true,
      fastMode: true,
      worker: true,
      skipEmptyLines: true,
      header: true,
      error: function(error) {
        console.error('papa parse error in calculator.js', error);
        setScreen('results');
      },
      complete: function(data){
        if(Array.isArray(data?.data) && data?.data.find(element=>element[key]===keyVal)){
          findROIColumn(data?.data.find(element=>element[key]===keyVal), rowKeyWords);
        }else{
          console.error('Row was not found', data)
          setScreen('results');
        }
      }
    });
  }

  //Function to convert the answer array into header keywords array (mainly for any metadata depenedent number fields)
  const pullHeaderKeyWords = (answerArray, questionData) => {
    let returnArray = [];
    let currentQuestionData = null;
    answerArray.forEach((element, key)=>{
      currentQuestionData = questionData[key];
      //Do not include csv keys
      if(currentQuestionData.isCsvKey !== true){
        //Radio and selects require no conversions
        if(currentQuestionData.fieldType === 'Select' || currentQuestionData.fieldType === 'Radio'){
          returnArray.push(element);
        }else{
          //handles all Number fields that are NOT the csv key//
          
          //finds the question that holds the options/metadata that we will need
          let indexOfFieldKey = questionData.findIndex((data)=> data.keyId === questionData[key].fieldKey);
          if(indexOfFieldKey > -1){
            let mapVals = questionData[indexOfFieldKey]?.options;
            //Finds the current selected answer of metadata question
            let currentFieldKeyAnswer = answerArray[indexOfFieldKey];
            //use current answer to find our map array
            mapVals = mapVals.find((data)=>data.value === currentFieldKeyAnswer);
            //Check element against array of ranges
            let foundMatch = findRange(mapVals, element);

            if(foundMatch?.keyName){
              returnArray.push(foundMatch.keyName);    
            }else{
              returnArray.push(null);    
              console.error('No matching field range for Calculator Step in calculator.js')
            }
          }else{
            setScreen('results');
            console.error('No matching fieldkey for Calculator Step in calculator.js')
          }
        }
      }
    });
    return returnArray;
  }

  const findRange = (rangeArray, enteredValueIn) => {
    return rangeArray?.stepMetaData.find((val)=>{
      let {lessThan, moreThan} = val;
      moreThan = moreThan !== '' ? parseInt(moreThan) : null;
      lessThan = lessThan !== '' ? parseInt(lessThan) : null;
      enteredValueIn = parseInt(enteredValueIn);
      // console.log(enteredValueIn, moreThan, lessThan);
      switch(true){
        case (moreThan && lessThan && (enteredValueIn > moreThan) && (enteredValueIn < lessThan)):
          // console.log(enteredValueIn, ' is between ', moreThan, ' and ', lessThan, 'returns ', val);
          return true;
        case (moreThan && !lessThan && (enteredValueIn > moreThan)):
          // console.log(enteredValueIn, ' is more than ', moreThan, 'returns ', val);
          return true;
        case (lessThan && !moreThan &&(enteredValueIn < lessThan)):
          // console.log(enteredValueIn, ' is less than ', lessThan, 'returns ', val);
          return true;
        default:
          return false;
      }
    })
  }

  const findROIColumn = (row, headingValsArray) => {
    let rowHeadings = Object.keys(row);
    let indexOfAnswer = rowHeadings.findIndex((data)=>containsAll(headingValsArray, data));
    // console.log('row headings', headingValsArray);

    let answer = row[rowHeadings[indexOfAnswer]];
    if(answer){
      setFinalAnswer(answer);
      setScreen('results');
    }else{
      // console.error('no results found');
      setScreen('results');
    }
    return answer || null;
  }

  const containsAll = (array, string) =>{
    return array.every((element)=>string.includes(element));
  }

  const setNewValue = (value, index) => {
    formAnswers[calculatorQuestions[index].prompt] = value;
    let formAnswerArray = Object.values(formAnswers);

    //Check if images are provided for this type of question. Update Image if provided.
    if((calculatorQuestions[index].options.length > 0) && calculatorQuestions[index].options.find((question)=>question.image)){
      setImage(calculatorQuestions[index].options.find((question)=>question.value === value)?.image);
    }
    setFormAnswers(formAnswers);
    if((formAnswerArray.length === totalQuestions) && !formAnswerArray.includes('')){
      setValid(true);
    }

    if(currentStep + 1 < totalQuestions){
      setCurrentStep(currentStep + 1);
    }else{
      if((currentStep + 1 === totalQuestions) && !formAnswerArray.includes('')){
        let keyIndex = calculatorQuestions.findIndex((item)=>item.isCsvKey === true);
        setScreen('loading');
        getResults(dataSheet.url, calculatorQuestions[keyIndex].keyId ,formAnswerArray[keyIndex], pullHeaderKeyWords(formAnswerArray, calculatorQuestions));
      }
      else{
        setValid(false);
      }
    }
  }

  //Update button text
  let buttonText;
  switch(currentStep){
    case 0: 
      buttonText = 'Start Calculation';
      break;
    case currentStep === calculatorQuestions.length - 1:
      buttonText = 'Calculate Roi';
      break;
    default:
      buttonText = 'Continue';
      break;
  }

  return (
    <>
      <Hero size="Full" location={location} className={calculator} data={{backgroundImage}}>
        {
          screen === 'calculator' &&
          <div>
            {
              image && <Image className={calculator__imageTop} data={image}/>
            }
            <nav className={calculator__nav}>
              <ol>
                {
                  calculatorQuestions.map((question, key)=><li key={key}><button aria-current={key === currentStep ? 'step' : false} onClick={()=>setCurrentStep(key)} aria-label={`${getTranslation('Question')} ${key+1}`} /></li>)
                }
              </ol>
            </nav>
            {
              calculatorQuestions && 
              <Question step={currentStep} data={calculatorQuestions[currentStep]} buttonText={buttonText} value={formArray[currentStep]} setNewValue={setNewValue} >
              {
                !valid && 
                <div>{getTranslation('Calculating')}
                  {formArray?.map((result, i)=>result === '' ? <button onClick={()=>setCurrentStep(i)}>{i + 1}</button> : '')}
                </div>
              }
              </Question>
            }
          </div>
        }
      {
        screen === 'loading' && 
        <div className={calculator__loading} >
          <div>
            <p>{getTranslation('Calculating')}...</p>
            {
              image && <Image className={calculator__imageBottom} data={image}/>
            }
            <div className={calculator__loading__loader}>
              <Loading className="loader--dots--white" /> 
            </div>
          </div>
        </div>
      }

      {
        screen === 'results' && 
        <Results result={finalAnswer} answers={formAnswers} {...resultsData} units={calculatorQuestions.map((question)=>question.unit)} />
      }
       
      </Hero>
      {pageBuilder && <RenderModules data={pageBuilder} />}
    </>
  )
}


export default CalculatorPage


export const CalculatorQuery = graphql`
query CalculatorQuery($locale : String! ) {
  datoCmsPageCalculator(locale : {eq: $locale}) {
    backgroundImage{
      ...BackgroundImageFragment
    }
    calculatorQuestions{
      ...CalcQuestionFragment
    }
    contactForm{
      ...HubspotFragment
    }
    dataSheet{
      url
    }
    lowestPercentageToDisplay
    pageBuilder {
      __typename
      ...ModuleFormFragment
      ...ModuleCTASideBySideFragment
      ...ModuleCTAOverlayFragment
      ...ModuleLogosFragment
      ...ModuleColumnFragment
      ...ModuleGridFragment
      ...ModuleSliderFragment
      ...ModuleSliderNoTabFragment
      ...ModuleTextContentFragment
      ...ModuleTilesFragment
    }
    locale
    noResultsFoundMessage{
      ...HeadingCopyLinkFragment
    }
    resultsLabel
    resultsCopy
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    title
  }
}
`



