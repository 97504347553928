import React from 'react';
import {radioInput} from './radioInput.module.scss';
import PropTypes from 'prop-types';

const RadioInput = ({data, currentValue, passValueFunc, ...other}) => {
  const { keyId, prompt, options} = data || {};
  return (
    <fieldset className={radioInput} {...other}>
      <legend className={`prompt`}>{prompt}</legend>
     {
       options.map((option,key)=>
        <label key={key}>
          <input name={`question-${keyId}`} checked={(currentValue === option.value) ? true : null} onChange={(e)=>passValueFunc(e.target.value)} id={`question-${keyId}`} type="radio" value={option.value} />
          <span>{option.value}</span>
        </label>
       )
     }
    </fieldset>
  )
}

RadioInput.propTypes = {
  data : PropTypes.shape({
    /**
     * uniqueId for fieldset
     */
    keyId: PropTypes.string,
    prompt: PropTypes.string,
     /**
     * array of options
     */
    options: PropTypes.array,
  }),
  /**
   * Current state to prefill
   */
  currentValue: PropTypes.string,
   /**
   * function to lift state
   */
  passValueFunc: PropTypes.func
}

export default RadioInput
