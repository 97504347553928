import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";

import Button from '../../../components/button/button';
import NumberField from '../../../components/inputs/textInput/textInput';
import RadioInput from '../../../components/inputs/radioInput/radioInput';
import SelectInput from '../../../components/inputs/selectInput/selectInput';
import HtmlBlock from '../../../components/htmlBlock/htmlBlock';
import {question, question__help, question__error} from './question.module.scss';

const map = {
  'Select': SelectInput,
  'Radio' : RadioInput,
  'Number' : NumberField
}

/**
 * Form Field
 * options text, select, radio
 */
const Question = ({children, data, step, className, buttonText, setNewValue, value}) => {
  const { helpTextDescription } = data || {};

  const [ currentField, setCurrentField ] = useState(value);
  const [ isValid, setIsValid ] = useState(true);
  const [ error, setError ] = useState('');

  useEffect(() => {   
    setCurrentField(value);
    setIsValid(true);
  },[value, data])

  const isValidRegex = (regexIn, input) => {
    let regex = new RegExp(`${regexIn}`);
    return  regex ? regex.test(input) : true; 
  }

  const isValidRange = (max, min, input) => {  
    if(max && max < input){
      return false;
    }else if(min && min > input){
      return false;
    }else{
      return true
    }
  }

  const formValidation = (data, input) => {
    const { failedRegexHelpText, maxValue, minValue, notWithinRangeWarning, regexForValidInput } = data || {};

    if((data.fieldType === 'Number') && input){
      var validRegex = isValidRegex(regexForValidInput, input);
      let intInput = input.replaceAll(',', '');
      var validRange = isValidRange(maxValue, minValue, intInput);

      if(validRegex && validRange){
        setNewValue(intInput, step);
      }else if(!validRegex){
        setError(failedRegexHelpText);
        setIsValid(false);
      }else if( !validRange){
        setError(notWithinRangeWarning);
        setIsValid(false);
      }
    }
    //check for empty non input vals
    else{
      if(input === '' || !input){
        setError('Please provide an answer before continuing.')
        setIsValid(false);
      }else{
        setIsValid(true);
        setNewValue(input, step);
      }
    }
  }

  const passValue = (value) => {
    setCurrentField(value)
  }

  const InputType = map[data?.fieldType];

  return (
    <div className={question}>
      {
      ( InputType && data ) && 
      <InputType step={step} data={data} currentValue={value || ''} passValueFunc={passValue} />
      }
      {
        helpTextDescription && <HtmlBlock className={question__help}>{helpTextDescription}</HtmlBlock>
      }
      {
        (!isValid || children) &&
        <div className={question__error}>
          {
            children ?
            children
            :
            <HtmlBlock  tag={`p`} >{error}</HtmlBlock>
          }
        </div>
      }
      <Button className="btn--primary--inverse" onClick={()=>formValidation(data, currentField)} >{buttonText}</Button>
    </div>
  )
}

export default Question

Question.propTypes = {
  data:PropTypes.shape({
    failedRegexHelpText: PropTypes.string,
    fieldKey: PropTypes.string,
    fieldType: PropTypes.oneOf(['Number', 'Select', 'Radio']),
    helpTextDescription: PropTypes.string,
    isCsvKey: PropTypes.bool,
    keyId: PropTypes.string,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    options: PropTypes.array,
    notWithinRangeWarning: PropTypes.string,
    prompt: PropTypes.string,
    regexForValidInput: PropTypes.string,
  }).isRequired
};

export const CalcQuestionFragment = graphql`
fragment CalcQuestionFragment on DatoCmsComponentCalculatorStep {
  failedRegexHelpText
  fieldKey
  fieldType
  helpTextDescription
  isCsvKey
  keyId
  maxValue
  minValue
  options{
    value
    image{
      ...ThirdImageFragment
    }
    stepMetaData{
      keyName
      lessThan
      moreThan
    }
  }
  notWithinRangeWarning
  prompt
  regexForValidInput
  unit
}
`;