import React, {useContext} from 'react';
import {selectInput} from './selectInput.module.scss';
import PropTypes from 'prop-types';
import { LangContext } from '../../../context/LocaleContext';

const SelectInput = ({data, currentValue, passValueFunc, ...other}) => {
  const { keyId, prompt, options } = data || {};
  const { getTranslation } = useContext(LangContext);

  return (
    <>      
      <label className={`prompt`} htmlFor={`question-${keyId}`}>{prompt}</label>
      <div className={selectInput} {...other}>
          {
            options && 
            <select defaultValue={currentValue} id={`question-${keyId}`} name="select" onChange={(e)=>passValueFunc(e.target.value)} >
            <option value={``}>{getTranslation('Select')}</option>
            {
              options.map((option,key)=><option key={key} value={option.value}>{option.value}</option>)
            }
          </select>
        } 
      </div>
    </>

  )
}

SelectInput.propTypes = {
  data : PropTypes.shape({
    /**
     * uniqueId for fieldset
     */
    keyId: PropTypes.string,
    prompt: PropTypes.string,
     /**
     * array of options
     */
    options: PropTypes.array,
  }),
  /**
   * Current state to prefill
   */
  currentValue: PropTypes.string,
   /**
   * function to lift state
   */
  passValueFunc: PropTypes.func
}

export default SelectInput;
