// extracted by mini-css-extract-plugin
export var absoluteSubmit = "calculator-module--absoluteSubmit--zvCCx";
export var actions = "calculator-module--actions--c9Z1C";
export var calculator = "calculator-module--calculator--x9GCl";
export var calculator__imageBottom = "calculator-module--calculator__image--bottom--0PQjB";
export var calculator__imageTop = "calculator-module--calculator__image--top--Fi+Zh";
export var calculator__loading = "calculator-module--calculator__loading--pbzaq";
export var calculator__loading__loader = "calculator-module--calculator__loading__loader--hHyYv";
export var calculator__nav = "calculator-module--calculator__nav--TO0oI";
export var chevron = "calculator-module--chevron--AWjdT";
export var focusVisible = "calculator-module--focus-visible--OkFfs";
export var hsErrorMsg = "calculator-module--hs-error-msg--ESJ6g";
export var hs_submit = "calculator-module--hs_submit--RzABv";
export var icon = "calculator-module--icon--isLsN";
export var input = "calculator-module--input--uIU1L";