import React from "react"
import PropTypes from 'prop-types';
import HtmlBlock from "../../../components/htmlBlock/htmlBlock";
import ContentBlock from "../../../components/contentBlock/contentBlock";
import HubSpot from "../../../components/form/hubspotForm/hubspotForm";
import {result, result__answers, result__results, result__results__label, result__results__percent, result__none} from './results.module.scss';

const Results = ({result : finalResult, answers, resultsLabel, resultsCopy, contactForm, lowestPercentageToDisplay, noResultsFoundMessage, units}) => {
  let prefillResults = Object.assign({}, answers);
  prefillResults['Results'] = finalResult;

  return (
    <div className={result}>
      <div className={result__answers}>
        {
            Object.values(answers).map((answer, i)=>{
              let unit = units[i] ? units[i] : undefined;
              return(
                <span key={i}>
                {unit ? answer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : answer} {unit}
                </span>
              ) 
            }
          )
        }
      </div>
      {
        (finalResult && (parseInt(finalResult) >= parseInt(lowestPercentageToDisplay))) ?
        <div className={result__results}>
          <span className={result__results__label} >{resultsLabel}</span>
          <p className={result__results__percent}><span>{finalResult}</span>%</p>
          <HtmlBlock data={resultsCopy}/>
        </div>
        :
        <ContentBlock className={result__none} data={noResultsFoundMessage[0]} />
      }
      {
        contactForm && <HubSpot prefillResults={prefillResults} className={`absoluteSubmit`} data={contactForm} />
      }
    </div>
  )
}

Results.propTypes = {
  screenState: PropTypes.oneOf(['results', 'error'])
}

export default Results




